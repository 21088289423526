<template>
    <div class="site-wrapper no-header">
        <div ref="smsWrapper" class="container">
            <div class="buttons hideForPrint">
                <span v-if="showBack" @click="goBack()" class="button is-info"> <font-awesome-icon icon="chevron-left" />&nbsp; Back </span>
            </div>

            <!-- {{ finalVesselID }} // {{ finalLogID }} // {{ isFleet }}

            <hr />
             -->

            <!-- <div style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 2rem; outline: none;">{{vesselData.vesselName}}</div>
          <h2 style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 1.2rem;">Custom Log Responses</h2>

          <hr class="narrow hideForPrint"> -->

            <div v-if="loadingData">
                <span><font-awesome-icon icon="cog" class="fa-spin" />&nbsp;{{ loadingText }}</span>
                <div class="spacer s40"></div>
            </div>

            <div v-else>
                <div v-if="dataError">
                    <h3 class="red">{{ errorMessage }}</h3>

                    <p>
                        Refresh the page and if the issue remains, please either give us a call on
                        <a href="tel:+61-447-139-382">+61 447 139 382</a>, or email <a href="mailto:help@offshoresms.com.au?subject=Question from Offshore SMS">help@offshoresms.com.au</a>.
                    </p>
                </div>

                <div v-else>
                    <h3 style="font-family: 'helvetica', sans-serif;color: #444;font-size: 1.2rem;font-weight: bold;text-transform: uppercase;margin-top: 1.5rem;display: block;">
                        {{ vesselName }}: {{ logData.customChecklistName }}
                    </h3>
                    <p style="font-family: 'helvetica', sans-serif;color: #444;font-size: 1rem;margin-top: 6px;margin-bottom: 5px;">
                        Logged at:
                        <span v-if="logData.logTimestamp">{{ showDate(logData.logTimestamp) }}</span>
                    </p>
                    <p style="font-family: 'helvetica', sans-serif;color: #444;font-size: 1rem;margin-top: 6px;margin-bottom: 5px;">By: {{ logData.postedBy }}</p>
                    <p v-if="logData.tripStarted" style="font-family: 'helvetica', sans-serif;color: mediumseagreen; font-size: 1rem;margin-top: 6px;margin-bottom: 25px;">
                        <font-awesome-icon icon="star" /> &nbsp; New trip activated from this log
                    </p>

                    <table
                        v-if="logData.customFieldAnswers && logData.customFieldAnswers.length > 0"
                        style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
                        class="table is-bordered is-fullwidth"
                    >
                        <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                            <tr v-for="(answer, index) in logData.customFieldAnswers" :key="`index-${index}`">
                                <th
                                    style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4;padding: 15px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;"
                                >
                                    <div>{{ answer.label }}</div>
                                    <div class="copy-link" @click="copyText(false, false, answer.label)">
                                        <font-awesome-icon icon="link" />
                                    </div>
                                </th>

                                <th
                                    style="font-size: 0.9rem;font-weight: normal !important;border: 1px solid #d4d4d4;padding: 15px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;"
                                >
                                    <!-- <div v-if="typeof answer.answer == 'string'">
                                    <div v-if="answer.answer.substr(0,5) == 'data:'">
                                        <img :src="answer.answer" alt="" />
                                    </div>
                                    <div v-else>{{answer.answer}}</div>
                                </div>
                                <div v-if="Array.isArray(answer.answer)"><div v-for="(snippet, idx) in answer.answer" :key="`idx-${idx}`">&nbsp; {{snippet}}</div></div> -->
                                    <div
                                        v-if="answer.type != 'doodle' && answer.type != 'dumbtext' && answer.type != 'dumbphoto' && answer.type != 'crew'"
                                        class="copy-link"
                                        @click="copyText(Array.isArray(answer.answer), answer.type == 'datetime', answer.answer)"
                                    >
                                        <font-awesome-icon icon="link" />
                                    </div>

                                    <div v-if="typeof answer.answer == 'string'">
                                        <div v-if="answer.type == 'doodle'">
                                            <img :src="answer.answer" alt="" />
                                        </div>
                                        <div v-if="answer.type == 'text'">
                                            {{ answer.answer }}
                                        </div>
                                        <div v-if="answer.type == 'paragraph'">
                                            <div v-for="(line, ix) in answer.answer.split('\n')" :key="`line-${ix}`">{{ line }}<br /></div>
                                        </div>
                                        <div v-if="answer.type == 'dropdown'">
                                            {{ answer.answer }}
                                        </div>
                                    </div>

                                    <div v-if="answer.type == 'dumbtext'">
                                        <div v-for="(line, ix) in answer.prompt.split('\n')" :key="`line2-${ix}`">{{ line }}<br /></div>
                                    </div>

                                    <div v-if="answer.type == 'dumbphoto'">
                                        <img :src="answer.prompt" alt=" " />
                                    </div>

                                    <div v-if="answer.type == 'upload'">
                                        <div @click="showZoom(img)" class="uploaded-image" v-for="(img, idx) in answer.answer" :key="`upload=${idx}`">
                                            <img :src="img" alt=" " />
                                        </div>
                                    </div>

                                    <div v-if="answer.type == 'crew'">
                                        <!-- {{ answer.answer }} -->
                                        <div class="crew-member" v-for="(crew, ix) in answer.answer" :key="`check-${ix}`">
                                            <span class="avatar-wrapper hideForPrint">
                                                <span v-if="crew.avatarUrl != ''" :style="{ backgroundImage: `url(${crew.avatarUrl})` }" class="avatar"></span>
                                                <span v-else class="avatar">{{ getAvatar(crew.name) }}</span>
                                            </span>
                                            <span class="crew-name">{{ crew.name }}</span>
                                        </div>
                                    </div>

                                    <!-- <div class="answer" v-if="item.type == 'text' || item.type == 'paragraph' || item.type == 'dropdown'">
                                  <div v-for="(text,idx) in getTextAnswer(item.answer)" :key="`text-${idx}`">
                                    <div v-for="line in text.split('\n')">{{line}}<br></div>
                                  </div>
                                </div> -->

                                    <div v-if="Array.isArray(answer.answer)">
                                        <div v-if="answer.type == 'checkboxes'">
                                            <div v-for="snippet in answer.answer" :key="snippet">&nbsp;• {{ snippet }}</div>
                                        </div>
                                        <div v-if="answer.type == 'pindrop'">
                                            <div>LATITUDE: {{ answer.answer[0] }}</div>
                                            <div>LONGITUDE: {{ answer.answer[1] }}</div>
                                            <div>What3Words: {{ answer.answer[2] }}</div>
                                        </div>
                                        <div v-if="answer.type == 'text' || answer.type == 'paragraph'">
                                            <div v-for="(text, idx) in getTextAnswer(answer.answer)" :key="`text-${idx}`">
                                                <div v-for="(line, inx) in text.split('\n')" :key="`line-${inx}`">{{ line }}<br /></div>
                                            </div>

                                            <!-- <div v-for="snippet in bit.answer" :key="snippet">&nbsp; {{snippet}}</div> -->
                                            <!-- <div v-for="(line, idx) in answer.answer.split('\n')" :key="`line-${idx}`">{{line}}<br></div> -->
                                        </div>
                                    </div>

                                    <div class="answer" v-if="answer.type == 'datetime'">
                                        {{ answer.answer == 'Choose an option' ? 'Not completed' : simpleDate(answer.answer) }}
                                    </div>
                                </th>
                            </tr>
                        </tbody>
                    </table>

                    <div class="buttons">
                        <span id="pdfButton" class="button is-primary" @click="savePDF()">{{ buttonText }}</span>
                    </div>
                </div>
            </div>

            <div class="copy-prompt hideForPrint" :class="{ visible: showCopyPrompt }">
                Copied to clipboard!
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale hideForPrint" v-bind:class="{ 'is-active': showZoomed }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Need more information?</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>

                <section class="modal-card-body">
                    <div class="full-img">
                        <img :src="selectedImage" alt="loading" />
                    </div>
                </section>

                <footer class="modal-card-foot">
                    <button class="button cancel" @click.prevent="close">Done</button>
                </footer>
            </div>
        </div>

        <div class="spacer s50"></div>
    </div>
</template>

<script>
import { db } from '../main.js';
//   import firebase from 'firebase/app';
//   import 'firebase/auth';
//   import 'firebase/firestore';
import NProgress from 'nprogress';
// import * as moment from 'moment';
// import VueHtml2pdf from 'vue-html2pdf';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

export default {
    name: 'FastTrackViewSms',

    props: {
        sentVesselID: String,
        sentVesselName: String,
        showBack: Boolean
    },

    components: {
        // VueHtml2pdf,
    },

    computed: {
        // today() {
        //   const dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'};
        //   // const t = new Date();
        //   return new Date().toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        // },
    },

    mounted: function() {
        let self = this;

        this.finalVesselID = this.$route.query.vid ? this.$route.query.vid : '';
        this.finalLogID = this.$route.query.lid ? this.$route.query.lid : '';
        this.fCode = this.$route.query.f ? this.$route.query.f : '0';
        this.isFleet = this.fCode == '1' ? true : false;

        console.log(this.finalVesselID, this.fCode, this.finalLogID, this.isFleet);

        if (this.finalVesselID != '' && this.finalLogID != '') {
            // if (this.isFleet) {
            //     this.loadFleetData();
            // } else {
            //     this.loadVesselData();
            // }
            this.loadVesselData();
        } else {
            setTimeout(() => {
                self.dataError = true;
                self.errorMessage = 'No log data found.';
                self.loadingData = false;
            }, 1000);
        }
    },

    data() {
        return {
            finalVesselID: '',
            finalLogID: '',
            loadingData: true,
            vesselData: {},
            logData: {},
            vesselName: '',
            isFleet: false,
            vesselOwnerID: '',

            buttonText: 'Download PDF',
            loadingText: 'Loading log data...',

            // today: '',

            htmlToPdfOptions: {
                margin: 0.3,

                filename: `Custom-Log.pdf`,

                image: {
                    type: 'jpeg',
                    quality: 2
                },

                enableLinks: false,

                html2canvas: {
                    scale: 1,
                    useCORS: true
                },

                jsPDF: {
                    unit: 'in',
                    format: 'a4',
                    orientation: 'portrait'
                }
            },
            hideForPrint: false,
            showCopyPrompt: false,

            dataError: false,
            errorMessage: '',
            showZoomed: false,
            selectedImage: ''
        };
    },

    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        //   onProgress($event) {
        //     console.log($event);
        //   },

        // async loadFleetlData() {

        // },

        async loadFleetData() {
            let self = this;
            await db
                .collection('vessels')
                .doc(this.finalVesselID)
                .get()
                .then(async doc => {
                    if (doc.exists) {
                        self.vesselOwnerID = doc.data().vesselOwnerID ? doc.data().vesselOwnerID : '';
                    }
                });

            if (this.vesselOwnerID != '') {
                console.log(this.vesselOwnerID);
                await db
                    .collection('companies')
                    .doc(this.vesselOwnerID)
                    .collection('customLogs')
                    .doc(this.finalLogID)
                    .get()
                    .then(async doc => {
                        if (doc.exists) {
                            self.logData = doc.data();
                            self.vesselName = doc.data().realVesselName ? doc.data().realVesselName : doc.data().vesselName;
                            self.dataError = false;
                        } else {
                            self.dataError = true;
                            self.loadingText = 'We could not load this log, sorry.';
                            this.loadingData = false;
                        }
                    });
            } else {
                this.dataError = true;
                this.errorMessage = 'Log report not found.';
                this.loadingData = false;
            }
        },

        async loadVesselData() {
            let self = this;

            let coll = this.isFleet ? 'companies' : 'vessels';
            console.log(this.isFleet, this.finalLogID);

            await db
                .collection(coll)
                .doc(this.finalVesselID)
                .collection('customLogs')
                .doc(this.finalLogID)
                .get()
                .then(async doc => {
                    if (doc.exists) {
                        self.logData = doc.data();
                        self.vesselName = doc.data().realVesselName ? doc.data().realVesselName : doc.data().vesselName;
                        self.dataError = false;
                    } else {
                        self.dataError = true;
                        self.loadingText = 'Loading backup data...';
                        self.loadFleetData();
                    }
                })
                .then(() => {
                    setTimeout(() => {
                        if (!self.dataError) {
                            self.loadingData = false;
                            self.errorMessage = '';
                        }
                    }, 500);
                })
                .catch(err => {
                    setTimeout(() => {
                        console.error(err);
                        self.loadingData = false;
                        self.dataError = true;
                        self.errorMessage = 'There was an error loading this log.';
                    }, 500);
                });
        },

        getAvatar(crewName) {
            // console.log(crewName);
            let parts = crewName.length > 0 ? crewName.split(' ') : [crewName];
            let initials = '';
            for (let i = 0; i < parts.length; i++) {
                if (parts[i].length > 0 && parts[i] !== '') {
                    initials += parts[i][0];
                }
            }
            return initials;
        },

        copyText(isArray, isDate, data) {
            console.log(isArray, isDate, data);
            let self = this;
            let dateString = isDate ? data.toDate().toLocaleString('en-US', { timeZone: 'Australia/Sydney' }) : '';
            let copyData = isArray ? data.join('\r\n') : isDate ? dateString : data;
            this.$copyText(copyData).then(
                function(e) {
                    console.log(copyData);
                    self.showCopied();
                },
                function(e) {
                    alert('This data cannot be copied');
                    console.log(e);
                }
            );
        },

        showCopied() {
            this.showCopyPrompt = true;
            setTimeout(() => {
                this.showCopyPrompt = false;
            }, 1000);
        },

        formatDate: function(sentDate, format) {
            // return moment(sentDate).format(format);
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            return fixDate;
        },

        close() {
            this.showZoomed = false;
            this.selectedImage = '';
        },

        showDate(d) {
            // if (d) console.log(d.toDate().toDateString());
            // if (d) console.log(d.toDate().toTimeString());
            // if (d) console.log(d.toDate().toLocaleTimeString());
            // return d ? d.toDate().toDateString() + ' (' + d.toDate().toLocaleTimeString() + ')' : '';
            // logData.logTimestamp.toDate().toDateString() (logData.logTimestamp.toDate().toTimeString())
            const dateOptions = {
                timeZone: 'Australia/Sydney',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            const t = new Date(d.toDate());
            return t.toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        },

        sorted: function(arr) {
            // Set slice() to avoid to generate an infinite loop!
            return arr.slice().sort(function(a, b) {
                return a.index - b.index;
            });
        },

        goBack: function() {
            this.$router.go(-1);
        },

        getTextAnswer(answer) {
            return Array.isArray(answer) ? answer : [answer];
        },

        simpleDate(sentDate) {
            console.log(sentDate);
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            const dateOptions = {
                timeZone: 'Australia/Sydney',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            const myDate = new Date(sentDate.toDate());
            return myDate.toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        },

        showZoom(img) {
            this.selectedImage = img;
            this.showZoomed = true;
        },

        savePDF: function() {
            this.buttonText = 'Preparing PDF. Please wait...';
            var self = this;

            var generalEmail = firebase.functions().httpsCallable('generalEmail');
            generalEmail({
                senderEmail: '',
                senderName: '',
                senderUserID: '',
                subject: 'Custom log response report printed: ' + self.logData.customChecklistName,
                message: 'Someone has printed a custom log response report for vessel ' + self.vesselName + ' (ID: ' + self.finalVesselID + ')'
            });

            // First we take the HTML of the page
            var html = document.documentElement.outerHTML;

            // Now we prepare the data that we pass to the API
            // In this example we want to hide the section with the example so we pass custom CSS
            // Note that you don't need to pass any other parameters if your defaults in the members area are already good
            var css = '.header, .copy-link { display:none !important;} #pdfButton {display:none;} .vue-back-to-top {display: none;} .hideForPrint {display: none;} .narrow {display: none;}';
            var data = { html: html, license: 'E197E92RdrKd', css: css };
            var serialized = Object.keys(data)
                .map(function(k) {
                    return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
                })
                .join('&');

            //console.log(serialized);

            // You can insert an "in progress" message here

            // We now prepare the API call
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {
                var a;
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // The PDF is now generated
                    // You can remove the "in progress" message here

                    // Now we show the PDF to the user
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    a.download = self.logData.customChecklistName + '.pdf';
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    //console.log("One");
                    self.buttonText = 'Download PDF';
                }
            };

            // This is the actual call to our API
            xhttp.open('POST', 'https://pdfmyurl.com/api', true);
            xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhttp.responseType = 'blob';
            xhttp.send(serialized);
            //console.log("Two");

            //NProgress.done();
        }
    }
};
</script>
<style lang="scss" scoped>
#app .header {
    position: relative !important;
}

.modal {
    &.modal-full-screen {
        img {
            max-width: 100%;
        }
    }
}

.site-wrapper .container {
    max-width: 1040px;
}

.edit-note {
    margin-top: 1rem;
    font-size: 1rem;
    color: steelblue;
    font-style: italic;
}

.copy-prompt {
    position: fixed;
    top: 80px;
    right: 20px;
    background: #a4dba4;
    color: #fff;
    padding: 1rem;
    border-radius: 6px;
    opacity: 0;
    transition: 0.2s;
    z-index: 9999;
    &.visible {
        opacity: 1;
    }
}

.uploaded-image {
    img {
        max-width: 100%;
        width: auto;
    }
}

hr {
    margin-bottom: 4rem !important;

    &.narrow {
        margin-bottom: 1.5rem !important;
    }
}

h3 {
    font-size: 1.2rem !important;
    text-transform: uppercase !important;
    margin-top: 2.5rem !important;
}

table {
    margin-top: 1rem !important;
    table-layout: fixed !important;
    width: 100% !important;

    tr {
        th {
            font-weight: normal !important;
            border: 1px solid #d4d4d4;
            padding: 12px 20px;
            font-family: 'Open Sans', sans-serif;
            text-align: left !important;
            word-break: break-word;
            position: relative;
            .copy-link {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 99;
                width: 40px;
                height: 40px;
                background: #f4f4f4;
                // display: flex;
                align-items: center;
                justify-content: center;
                display: none;
                cursor: pointer;
            }
            &:hover {
                .copy-link {
                    display: flex;
                }
            }
        }

        td {
            width: 50% !important;
            border: 1px solid #d4d4d4 !important;
            padding: 8px 12px;
            font-family: 'Open Sans', sans-serif;
            text-align: left !important;
            word-break: break-word;
            .link {
                margin-top: 5px;
            }
        }
    }
}
span {
    &.avatar {
        border-radius: 50%;
        border: none;
        width: 45px;
        height: 45px;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        line-height: 45px;
        background: cadetblue;
        color: white;
        font-weight: bold;
        letter-spacing: 1pt;
        margin-right: 0.6rem;
        background-position: center center;
        background-size: cover;
        transition: 0.3s;
    }
}
.crew-member {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding: 12px;
    // border: 1px solid #f0f0f0;
    margin-bottom: 12px;
    position: relative;
    .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        // background: #f0f0f0;
        // color: #444;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        margin-right: 12px;
    }
    .crew-name {
        // font-weight: bold;
    }
    .delete {
        position: absolute;
        top: 5px;
        right: 5px;
    }
}

.pagebreak {
    display: block !important;
    clear: both !important;
    page-break-after: always !important;
}

@media only screen and (max-width: 550px) {
    table,
    tbody,
    tr,
    th,
    td {
        display: block;
    }
    tr {
        margin-bottom: 8px;
        th {
            width: 100%;
            &:first-of-type {
                border-bottom: none !important;
            }
        }
    }
}
</style>
