<template>
    <div>
        <div class="info-wrapper">
            <div v-if="!saving" class="">
                <h2><font-awesome-icon icon="ship" /> &nbsp;{{ heading }}</h2>
                <h4>{{ intro }}</h4>
                <div class="spacer s20"></div>

                <div class="form-section" v-for="(question, idx) in questions" :key="`q-${idx}`">
                    <div class="notification">
                        <h3
                            :class="{
                                red: question.required && (question.answer == '' || question.answer == null || question.answer.length == 0 || question.answer == 'Choose an option')
                            }"
                        >
                            {{ question.title }}
                            <span :class="{ red: question.required }" v-if="question.required">*</span>
                        </h3>
                        <p class="field-prompt">{{ question.prompt }}</p>

                        <div class="question-type" v-if="question.type == 'text'">
                            <div class="text-input">
                                <input value="Text box" type="text" placeholder="Type answer..." v-model="question.answer[0]" />
                            </div>
                        </div>

                        <div class="question-type" v-if="question.type == 'paragraph'">
                            <div class="text-input">
                                <textarea class="textarea" v-model="question.answer[0]"></textarea>
                            </div>
                        </div>

                        <div v-if="question.type == 'dropdown'" class="question-type form-group-reg">
                            <div class="select">
                                <select v-model="question.answer[0]">
                                    <option value="Choose an option">Choose an option</option>
                                    <option v-for="(option, ix) in question.options" :value="option" :key="`option-${ix}`">{{ option }}</option>
                                </select>
                            </div>
                            <div class="spacer s10"></div>
                        </div>

                        <div v-if="question.type == 'checkboxes'" class="question-type checkbox-wrapper">
                            <div class="field" v-for="(option, ix) in question.options" :key="`check-${ix}`">
                                <input class="is-checkradio" type="checkbox" :id="option" :value="option" v-model="question.answer" />
                                <label :for="option">{{ option }}</label>
                            </div>
                        </div>
                    </div>

                    <!-- {{ question.answer }} -->
                </div>

                <div class="spacer s30"></div>
                <div class="form-section">
                    <div class="notification">
                        <h3>UPLOADS (Photos or PDFs)</h3>
                        <p class="bold">
                            A copy of your Certificate of Survey, Certificate of Operation, and at least one good photo of the boat really helps.
                        </p>
                        <div class="spacer s20"></div>
                        <div v-if="myUploads.length > 0" class="image-div">
                            <ul class="uploads">
                                <li class="image-wrap" v-for="(image, index) in myUploads" :key="`image2-${index}`">
                                    <span v-if="image.type == 'pdf'">
                                        <img src="@/assets/img/pdf-image-placeholder.png" />
                                    </span>
                                    <span v-else>
                                        <img :src="image.imageUrl" />
                                    </span>

                                    <span class="cross" @click="deleteUpload(index)"><font-awesome-icon icon="times"/></span>
                                </li>
                            </ul>
                            <div class="spacer s40"></div>
                        </div>
                        <div class="clear"></div>
                        <div v-if="myUploads.length < 5">
                            <vue-dropzone ref="imgDropZone" id="customdropzone" :options="dropzoneOptions" @vdropzone-complete="afterComplete"></vue-dropzone>
                        </div>
                        <div class="spacer s20"></div>
                    </div>
                    <div class="clear"></div>
                    <div class="spacer s20"></div>

                    <div v-if="!saving" class="buttons centered">
                        <button :disabled="incompleteFields.length > 0" class="button is-primary" @click="review()">
                            Submit Answers
                        </button>
                    </div>

                    <div v-if="saving" class="save-message">{{ saveMsg }}</div>
                </div>
            </div>

            <!-- {{ incompleteFields }} -->
            <span class="red align-right">* Answer required</span>

            <hr />

            <div class="spacer s100"></div>

            <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showReviewModal }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card">
                    <header class="modal-card-head is-primary">
                        <p class="modal-card-title">
                            {{ formSubmitted ? 'Log Complete' : incompleteFields.length > 0 ? 'Errors found' : 'Ready to Submit' }}
                        </p>
                        <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
                    </header>

                    <section class="modal-card-body">
                        <div v-if="formSubmitted">
                            <p class="green bold">Vessel info sent at: {{ today }}</p>
                            <div class="spacer s10"></div>
                        </div>

                        <div v-else>
                            <div v-if="!reviewing">
                                <div v-if="reviewed">
                                    <p class="green bold">{{ today }}</p>
                                    <div class="spacer s20"></div>
                                    <div class="review-form">
                                        <div class="review-field">
                                            <label class="label" :class="{ red: formUserName == '' }">Your name:</label>
                                            <input type="text" class="input" placeholder="Type your full name" v-model="formUserName" />
                                        </div>
                                        <div class="spacer s10"></div>

                                        <div class="review-field">
                                            <!-- <input
                                                class="is-checkradio normal"
                                                type="checkbox"
                                                id="localDifferent"
                                                v-model="sendEmail"
                                            />
                                            <label for="localDifferent"
                                                >I'd like to </label
                                            > -->
                                            <div class="spacer s10"></div>
                                            <div v-if="sendEmail">
                                                <label class="label" :class="{ red: !emailValid(formUserEmail) }">Your email address:</label>
                                                <input type="text" class="input" placeholder="Type your email" v-model="formUserEmail" />
                                            </div>
                                            <div class="spacer s20"></div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="spacer s10"></div>
                                    <p>{{ incompleteFields.length }} incomplete fields found. Please go back and check answers.</p>
                                    <div class="spacer s10"></div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <footer v-if="!formSubmitted" class="modal-card-foot">
                        <button class="button" @click="close()">Cancel</button>
                        <button :disabled="incompleteFields.length > 0 || formUserName == '' || (sendEmail && !emailValid(formUserEmail))" class="button is-primary" @click="saveLog()">
                            Save Log
                        </button>
                    </footer>
                    <footer v-else class="modal-card-foot">
                        <button class="button is-primary" @click="close()">All Done!</button>
                    </footer>
                </div>
            </div>
        </div>

        <div class="clear"></div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';

import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
let uuid = require('uuid');

export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    data: function() {
        return {
            saving: false,
            saveMsg: '',
            heading: 'heading coming!',
            intro: 'intro here..',
            questions: [],
            answers: {
                one: '',
                two: '',
                three: ''
            },
            loadingQuestions: false,
            formUserName: '',
            sendEmail: true,
            formUserEmail: '',
            showReviewModal: false,
            formSubmitted: false,
            reviewing: false,
            reviewed: true,
            reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                addRemoveLinks: false,
                acceptedFiles: '.jpg, .jpeg, .png, .pdf',
                dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Click here to add a photo or PDF file</p><p class="form-text">(Allowed Files: .jpg, jpeg, .png or .PDF up to 2MB)</p>`,
                maxFilesize: 2
            },
            myUploads: [],
            myPDFs: []
        };
    },
    mounted() {
        NProgress.start();
        this.loadSetup();
    },
    computed: {
        incompleteFields() {
            return this.questions.filter(m => m.required && (m.answer == '' || m.answer == null || m.answer.length == 0 || m.answer == 'Choose an option')).map(n => n.title);
        },
        showButton() {
            return this.testimonial != '' && this.author != '';
        },
        user() {
            return this.$store.getters.getUser;
        },
        today() {
            const dateOptions = {
                timeZone: 'Australia/Sydney',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            return new Date().toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        }
    },
    methods: {
        loadSetup() {
            let self = this;
            db.collection('onboarding')
                .doc('newVesselQuestions')
                .get()
                .then(doc => {
                    self.heading = doc.data().heading;
                    self.intro = doc.data().intro;
                })
                .then(_ => {
                    self.loadQuestions();
                })
                .catch(err => {
                    console.log('Error', err);
                    self.loadingQuestions = false;
                    NProgress.done();
                });
        },
        async loadQuestions() {
            let self = this;
            db.collection('onboarding')
                .doc('newVesselQuestions')
                .collection('questions')
                .orderBy('index', 'asc')
                .onSnapshot(querySnapshot => {
                    self.questions = [];
                    querySnapshot.forEach(doc => {
                        // console.log(doc.data());
                        self.questions.push(doc.data());
                    });
                });

            setTimeout(() => {
                self.loadingQuestions = false;
                NProgress.done();
            }, 1000);
        },
        emailValid(email) {
            return this.reg.test(email) && email.includes('@');
        },

        close() {
            console.log('close');
            if (this.formSubmitted) {
                this.$router.push({ name: 'new-home' });
            } else {
                this.showReviewModal = false;
                this.reviewed = false;
                this.reviewing = false;
            }
        },
        submit() {
            let self = this;
            NProgress.start();
            this.saving = true;
            this.saveMsg = 'Saving your testimonial...';
            let newData = {
                author: self.author,
                town: self.town,
                testimonial: self.testimonial,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
            };
            db.collection('testimonials')
                .add(newData)
                .then(function(doc) {
                    self.saveMsg = 'Thank you! We really value your support!';
                    NProgress.done();
                    setTimeout(() => {
                        self.$router.push({ name: 'new-home', params: {} });
                    }, 3000);
                    let addRevision = firebase.functions().httpsCallable('addRevision');
                    addRevision({
                        userID: '',
                        userName: self.town ? self.town : 'from somewhere',
                        description: self.author + ' has left a new testimonial'
                    });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        review() {
            let self = this;
            NProgress.start();
            // this.formUserName = this.formUserName;
            this.finalAnswers = [];
            this.reviewed = false;
            this.reviewing = true;
            setTimeout(() => {
                this.reviewing = false;
                this.reviewed = this.incompleteFields.length == 0;
                this.showReviewModal = true;
                NProgress.done();
            }, 500);
        },

        // async cleanAnswers() {
        //     this.finalAnswers.forEach(answer => {
        //         console.log(answer.type);
        //         if (answer.type == 'datetime') answer.answer = new Date(answer.answer);
        //     });
        // },

        async afterComplete(upload) {
            NProgress.start();
            let self = this;
            // console.log('currentUpload', upload);
            this.currentUpload = upload;
            this.saveImageModal = true;
            this.isLoading = true;
            this.imageChanged = true;
            this.somethingChanged = true;

            var imageName = uuid.v1();
            // this.isLoading = true;
            try {
                //save image
                let file = upload;
                var metadata = {
                    // contentType: "image/png"
                    contentType: upload.type ? upload.type : 'image/png'
                };
                var suffix = upload.type ? (upload.type == 'application/pdf' ? 'pdf' : upload.type == 'image/jpeg' ? 'jpeg' : 'png') : 'png';
                console.log(upload.type);
                var storageRef = firebase.storage().ref();
                var imageRef = storageRef.child(`new-vessel-info/${imageName}.${suffix}`); // use upload.type to infer the suffix? ie .png or .pdf?
                // console.log(imageRef);
                await imageRef.put(file, metadata);
                var downloadURL = await imageRef.getDownloadURL();
                // self.myUploads.push(downloadURL);
                self.myUploads.push({ type: suffix, imageUrl: downloadURL });
                NProgress.done();
            } catch (error) {
                console.log(error);
                NProgress.done();
            }
            this.$refs.imgDropZone.removeFile(upload);
        },

        deleteUpload(index) {
            this.myUploads.splice(index, 1);
        },

        async saveLog() {
            NProgress.start();
            let self = this;
            let notifyEmails = [];
            if (this.sendEmail) notifyEmails.push(this.formUserEmail);
            console.log(notifyEmails);

            let newData = {
                userName: self.formUserName,
                userEmail: self.formUserEmail,
                questions: self.questions,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                uploads: self.myUploads
            };
            let emailList = [self.formUserEmail, 'help@offshoresms.com.au'];
            db.collection('onboarding')
                .doc('newVesselQuestions')
                .collection('newVesselInfo')
                .add(newData)
                .then(docRef => {
                    let emailData = {
                        logID: docRef.id,
                        userName: self.formUserName,
                        vesselName: self.questions[0].answer[0],
                        emailList: emailList
                    };
                    console.log(emailData);
                    var newVesselNotification = firebase.functions().httpsCallable('newVesselNotification');
                    newVesselNotification(emailData);
                })
                .catch(error => {
                    console.log(error);
                    NProgress.done();
                });

            setTimeout(() => {
                // window.location.reload();
                self.formSubmitted = true;
                NProgress.done();
            }, 500);
        }
    }
};
</script>

<style lang="scss" scoped>
.info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 3rem auto 10rem;
    padding: 0 20px;
    max-width: 1000px;
    h2 {
        font-family: 'Roboto Slab', serif;
        color: #2562a4;
        font-size: 1.5rem;
        line-height: 2.5rem;
        margin: 0 auto 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    h4 {
        text-align: center;
    }
}

.form-section {
    margin-bottom: 15px;
    &.last {
        border: none;
    }
    .notification {
        text-align: left;
        background-color: #f9f9f9;
        margin-bottom: 12px;
        h3 {
            color: cadetblue;
            font-family: 'Roboto Slab', serif;
            font-size: 1.1rem;
            margin-bottom: 8px;
            &.red {
                color: red;
            }
        }
        .field-prompt {
            margin-bottom: 12px;
        }
        .question-type {
            input[type='text'] {
                height: auto;
                padding: 15px;
            }
        }
    }
    .padded-hr {
        margin: 2rem 0;
    }
}
.review-field {
    input[type='text'] {
        height: auto;
        padding: 15px;
    }
}

ul.uploads {
    list-style: none;
    // height: 250px;
    height: auto;
    margin-bottom: 2rem;

    .sorting-list {
        display: inline-flex;
        height: 100%;
    }

    li {
        display: inline-flex;
        max-width: 200px;
        min-width: 100px;
        margin: 0 1rem 1rem 0;
        height: 100%;
        float: left;
        // padding: 15px;
        border: 1px solid #ddd;
        position: relative;
        cursor: pointer;

        img {
            width: 100%;
            align-self: center;
        }

        &.show {
            width: 100%;
            max-width: none;
            display: inline-table;

            img {
                max-width: none;
            }
        }

        .caption {
            border: none;
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 0.9rem;
            width: 100%;
            padding: 8px;
            background: #f4f4f4;
            text-align: center;
            border: none;
            border-radius: 0;

            input {
                border: none;
                background: none;
                padding: 0;
                height: auto;
                min-height: 0;
                margin: 0;
                border-radius: 0;
                font-size: 0.9rem;
                line-height: 1rem;
                text-align: center;
            }

            textarea {
                min-height: 0;
                border: none;
                background: none;
                padding: 0;
                height: auto;
                min-height: 0;
                margin: 0;
                border-radius: 0;
                font-size: 0.9rem;
                line-height: 1rem;
                text-align: center;
            }
        }

        .cross {
            justify-content: center;
            position: absolute;
            top: -12px;
            right: -12px;
            z-index: 999;
            background: #dedede;
            padding: 10px;
            width: 35px;
            height: 35px;
            display: flex;
            border-radius: 50%;
            transition: 0.2s;
            cursor: pointer;

            &:hover {
                color: red;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .split-wrapper {
        flex-direction: column;
        padding: 0;
        .left-side {
            width: 100%;
            padding-top: 0;
            &:after {
                content: '';
                height: 1px;
                background-color: #e9e9e9;
                width: 80%;
                margin: 3rem auto 0;
                display: block;
            }
        }
        .right-side {
            width: 100%;
            padding-top: 1rem;
            .save-message {
                text-align: center;
            }
        }
    }
}
</style>
