<template>
    <div>
        <div class="header-image">
            <div class="header-content">
                <h1>Offshore SMS Privacy Policy and Terms & Conditions</h1>
            </div>
        </div>

        <div class="site-wrapper">
            <div class="container">
                <ul class="scroller">
                    <li><router-link to="useful-links">Useful Links</router-link></li>
                    <li class="is-active"><a aria-current="page">Privacy Policy, Terms & Conditions</a></li>
                </ul>

                <div class="spacer s30"></div>

                <div class="content">
                    <p>
                        Please take the time to read this privacy policy and terms and conditions statement. By using Our Website and the services and information offered on Our Website, you are
                        agreeing to these terms and conditions.
                    </p>
                    <p>
                        If you purchase any online subscriptions or services through Offshore SMS, there may be additional terms and conditions relating to the purchase. Please make sure you agree
                        with these terms and conditions, which you may read prior to making your purchase.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Definitions</h4>
                    <p><strong>Products and Services</strong> means the cloud based Safety Management System tools and apps developed by Offshore SMS.</p>
                    <p><strong>The Website</strong> means the web app located at www.offshoresms.com.au, and includes any mobile or app versions of OffshoreSMS.</p>
                    <p><strong>We / Us</strong> etc means Offshore SMS Pty Ltd and any holding companies, affiliates, employees, officers, agents or assigns.</p>

                    <div class="spacer s20"></div>
                    <h4>Accuracy of content</h4>
                    <p>
                        We have taken proper care and precautions to ensure that the information we provide on this Website is accurate. However, we cannot guarantee, nor do we accept any legal
                        liability arising from or connected to, the accuracy, reliability, currency or completeness of anything contained on this Website or on any linked site.
                    </p>
                    <p>The information contained on this Website should not take the place of professional advice or proper due diligence and care.</p>

                    <div class="spacer s20"></div>
                    <h4>Use</h4>
                    <p>
                        The Website is made available for your use on your acceptance and compliance with these terms and conditions. By using this Website, you are agreeing to these terms and
                        conditions.
                    </p>
                    <p>You agree that you will use this website in accordance with all applicable local, state, national and international laws, rules and regulations.</p>
                    <p>
                        You agree that you will not use, nor will you allow or authorise any third party to use, the Website for any purpose that is unlawful, defamatory, harassing, abusive,
                        fraudulent or obscene way or in any other inappropriate way or in a way which conflicts with the Website or the Services.
                    </p>
                    <p>
                        If you contribute to our forum (if any) or make any public comments on this Website which are, in our opinion, unlawful, defamatory, harassing, abusive, fraudulent or obscene
                        or in any other way inappropriate or which conflict with the Website or the Services offered, then we may at our discretion, refuse to publish such comments and/or remove them
                        from the Website.
                    </p>
                    <p>We reserve the right to refuse or terminate service to anyone at any time without notice or reason.</p>

                    <div class="spacer s20"></div>
                    <h4>Passwords and logins</h4>
                    <p>You are responsible for maintaining the confidentiality of your passwords and login details and for all activities carried out under your password and login.</p>
                    <p>
                        All user generated passwords are encrypted in the database and not visible even to OffshoreSMS developers and staff. In the event that we generate a temporary user-friendly
                        password for customers in the event of a lost or locked-out login attempt, we expect that the user will change that password at the earliest convenience.
                    </p>
                    <p>
                        As of early 2023, the OffshoreSMS web and app platforms have moved to a multi-factor authentication system allowing alternative methods of user validation and authentication.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Collection of Data</h4>
                    <p>
                        While the OffshoreSMS app and website are free to download and to use, creating a free profile will allow us to provide tailored services and content to users. Specifically we
                        only collect your name, a contact phone number and an email address, all of which are only used to provide customised content to you, for example to manage your profile, to add
                        vessels and to allow you to be added as crew on other vessels.
                    </p>
                    <p>
                        We also require all free user accounts to have a secure password and access to either a validated mobile phone number or email address to enhance the protection of your data
                        and to prevent other users acting as you.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Privacy of User Data</h4>
                    <p>
                        We will never share any user information, passwords, email addresses or other user data with any other OffshoreSMS users or with any third party businesses or individuals
                        without the express consent of the user.
                    </p>
                    <p>We place the highest value on the trust that our customers have placed in us and will always go out of our way to maintain that trust.</p>
                    <p>If you have any concerns or would like to know more about how your data is stored, please <router-link to="feedback">contact us</router-link>.</p>

                    <div class="spacer s20"></div>
                    <h4>Prominent Disclosure & Consent Requirement</h4>
                    <p>
                        The OffshoreSMS app may display an option to invite contacts from your device's contacts list to join the OffshoreSMS community. Inviting one or more of your contacts will send
                        them a text message saying that you think they will find value in downloading the OffshoreSMS app, and nothing more.
                    </p>
                    <p>We will never share your contacts' data or use their contact information in any way outside of the OffshoreSMS app.</p>
                    <p>
                        In addition to this, the OffshoreSMS app allows users to upload photos of their vessels, and themselves to use as profile avatars. Some of the vessel logs also provide users
                        with the option to upload photos of vessel defects, features, inventory, repairs or maintenance related content. Some images may contain metadata that is uploaded and stored in
                        Google's secure Cloud Storage platform. OffshoreSMS will never share any users' photos, uploads or associated metadata with any third party or any platform outside of
                        OffshoreSMS and Google Cloud.
                    </p>
                    <p>
                        By uploading an image from your own device, you agree that the image only may be shared with other users of the OffshoreSMS app, or of the vessel/s that you work on or manage
                        in the app.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Indemnification for loss or damage</h4>
                    <p>
                        You agree to indemnify Us and hold Us harmless from and against any and all liabilities or expenses arising from or in any way related to your use of this Website or the
                        Services or information offered on this Website, including any liability or expense arising from all claims, losses, damages (actual and consequential), suits, judgments,
                        litigation costs and solicitors fees of every kind and nature incurred by you or any third parties through you.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Intellectual property and copyrights</h4>
                    <p>
                        We hold the copyright to all content on this Website that is created by OffshoreSMS or ships with this website, including all uploaded files, layout design, data, graphics,
                        articles, file content, codes, news, tutorials, videos, reviews, and databases contained on the Website or in connection with the Services. You must not use or replicate our
                        copyright material other than as permitted by law. Specifically, you must not use or replicate our copyright material for commercial purposes unless expressly agreed to by Us,
                        in which case we may require you to sign a Licence Agreement. This does not include content, files or images created or uploaded to the platform by registered users of
                        OffshoreSMS.
                    </p>
                    <p>
                        If you wish to use content, images or other of our intellectual property, you should submit your request to us at the following email address:
                        <strong><a href="mailto:help@offshoresms.com.au?subject=Privacy Policy Enquiry from OffshoreSMS">help@offshoresms.com.au</a></strong>
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Trademarks</h4>
                    <p>
                        Any trademarks and logos contained on this Website will be the trademarks of Offshore SMS Pty Ltd or its parent company. Use of these trademarks is strictly prohibited except
                        with Our express, written consent.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Links to external websites</h4>
                    <p>
                        This Website may contain links that direct you outside of this Website. These links are provided for your convenience and are not an express or implied indication that we
                        endorse or approve of the linked Website, its contents or any associated website, product or service. We accept no liability for loss or damage arising out of or in connection
                        with your use of these sites.
                    </p>
                    <p>
                        You may link to our articles or home page. However, you should not provide a link which suggests any association, approval or endorsement on our part in respect to your
                        website, unless we have expressly agreed in writing. We may withdraw our consent to you linking to our site at any time by notice to you.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Limitation of Liability</h4>
                    <p>
                        We take no responsibility for the accuracy of any of the content or statements contained on this Website or in relation to our Services. Statements made are by way of general
                        comment only and you should satisfy yourself as to their accuracy. Further, all of our Services are provided without a warranty with the exception of any warranties provided by
                        law. We are not liable for any damages whatsoever, incurred as a result of or relating to the use of the Website or our Services.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Confidentiality</h4>
                    <p>
                        All personal information you give us will be dealt with in a confidential manner in accordance with our Privacy Policy. However, due to circumstances outside of our control, we
                        cannot guarantee that all aspects of your use of this Website will be confidential due to the potential ability of third parties to intercept and access such information.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Governing Law</h4>
                    <p>
                        These terms and conditions are governed by and construed in accordance with the laws of Australia. Any disputes concerning this website are to be resolved by the courts having
                        jurisdiction in Australia.
                    </p>
                    <p>We retain the right to bring proceedings against you for breach of these Terms and Conditions, in your country of residence or any other appropriate country or jurisdiction.</p>
                </div>

                <hr />

                <p class="heading">Last Edit: 10 May, 2023</p>

                <div class="spacer s90"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUs',

    components: {},

    mounted: function() {},

    computed: {},

    data: function() {
        return {};
    },

    methods: {}
};
</script>

<style lang="scss" scoped>
.header-image {
    background: url('../assets/cyber2.jpg') no-repeat center center;
    color: #fff;
}

.avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    background: steelblue;
    display: block;
    margin: 0 2rem 2rem 0;
    float: left;
    transition: 0.3s;
    background-repeat: no-repeat;
    background-position: center center;
    &.company {
        background: url('../assets/img/offshore-sms-icon.jpg') no-repeat center center;
        background-size: cover !important;
    }
    &.steve {
        background: url('../assets/img/stevefraser.jpg') no-repeat center center;
        background-size: contain;
    }
}
.avatar-content-box {
    height: 150px;
    width: calc(100% - 200px);
    float: left;
    position: relative;
    .content-align {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            width: 0;
            bottom: -10px;
            background: steelblue;
            height: 5px;
            transition-property: width;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
        }
        h2 {
            font-size: 1.5rem;
            line-height: 1.8rem;
        }
        span {
        }
    }
}
.header-content {
    text-align: left;
}
.social {
    a {
        transition: 0.3s;
        font-size: 2rem;
    }
}
.content {
    p {
        margin-bottom: 1rem;
        color: #333;
    }
}

@media only screen and (max-width: 479px) {
    .avatar {
        float: none;
        margin: 0 auto 1rem;
    }
    .avatar-content-box {
        width: 100%;
        text-align: center;
    }
}
</style>
